<template>
  <div class="about">
    <b-container>
 <b-table striped dark :items="getUsers"></b-table>
    </b-container>
  </div>
</template>

<script>
//import ContactForm from "@/components/ContactForm.vue";

import { collection, getDoc, getDocs, db } from "@/firebase";
import { usersCollection } from "@/db"
export default {
  data() {
    return {
      users: [],
    };
  },
  components: {
    // ContactForm,
  },
  asyncComputed: {
    async getUsers() {
     let userlist = []
      const querySnapshot = await getDocs(usersCollection);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        if(doc.data().firstName){
          userlist.push({
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          linkedin: doc.data().liURL,
          email: doc.data().email,
          id: doc.id,
        });
      }});
      return userlist;
    },
  },
};
</script>

<style scoped>
.table-dark {
    color: #000;
    background-color: #ffffff;
}
</style>
